import http from '@/utils/http'

// 前两个组件的数据
export function materialAnalysis (p) {
  return http.post('/unibu/analysis/material', p)
}

// 近12个月区域混凝土价格列表
export function materialAreaAnalysis (p) {
  return http.post('/unibu/analysis/materialArea', p)
}

// 混凝土价格可选区域
export function analysisGetArea (p) {
  return http.post('/unibu/analysis/getArea', p)
}
