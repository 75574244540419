<template>
  <div class="p-20">
    <el-alert
      class="border-shadow"
      v-if="materialNoticeObj"
      style="margin-bottom: 20px;"
      :title="materialNoticeObj.title"
      type="success">
      <div class="flex">
        <div class="text-1line" style="max-height: 750px;">
          {{materialNoticeObj.content}}
        </div>
        <span class="ml-15 pointer green" @click="showMessage">查看详情</span>
      </div>
    </el-alert>

    <div class="mb-28 border-shadow">
      <div class="data-title">
        <img src="@/assets/imgs/analysis/priceAnalysis/icon1.png" alt="">
        <span class="ml-12">价格指数</span>
      </div>

      <div class="data-content flex justify-around">
        <el-table :data="table1" size="small">
          <el-table-column label="区域" prop="name"></el-table-column>
          <el-table-column label="价格指数" prop="price"></el-table-column>
          <el-table-column label="涨跌">
            <template v-slot="{row}">
              <span v-if="row.RFP0=='0'">{{row.RFP0}}</span>
              <span v-else :class="row.RF0=='Rise'?'red':'green'">{{row.RFP0}} {{row.RF0=='Rise'?'↑':'↓'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="涨跌幅" prop="RFV0"></el-table-column>
          <el-table-column label="去年同期（价格指数）" prop="RFP1"></el-table-column>
          <el-table-column label="同比涨跌" prop="RFV1">
            <template v-slot="{row}">
              <span v-if="row.RFV1=='0'">{{row.RFV1}}</span>
              <span v-else :class="row.RF1=='Rise'?'red':'green'">{{row.RFV1}} {{row.RF1=='Rise'?'↑':'↓'}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="mb-28 border-shadow">
      <div class="data-title purple">
        <img src="@/assets/imgs/analysis/priceAnalysis/icon2.png" alt="">
        <span class="ml-12">价格指数走势</span>
      </div>
      <div class="data-content flex justify-around">
        <Chart :value="lineData" :min="60" :max="140"></Chart>
      </div>
    </div>

    <!-- 近12个月区域价格 -->
    <div class="border-shadow">
      <div class="data-title green flex justify-between align-center">
        <div class="flex align-center">
          <img src="@/assets/imgs/analysis/priceAnalysis/icon3.png" alt="">
          <span class="ml-12">近12个月价格</span>
        </div>
        <el-select class="w-100 mr-15" v-model="areaId" @change="handlerAreaChange">
          <el-option v-for="item in areaList" :key="item.id" :value="item.id" :label="item.areaName"></el-option>
        </el-select>
      </div>
      <div class="data-content flex justify-around">
        <el-table :data="table2" size="small">
          <el-table-column label="规格型号" prop="model"></el-table-column>
          <el-table-column label="材料名称" prop="materialName" min-width="150"></el-table-column>
          <el-table-column label="单位" prop="unit"></el-table-column>
          <template v-if="table2.length > 0">
            <el-table-column  v-for="(item,index) in table2[0].date" :key="item" :label="item">
              <template v-slot="{row}">
                {{row.prices[index]}}
              </template>
            </el-table-column>
          </template>
          <!-- <el-table-column label="涨跌幅">
                <template v-slot="{row}">
                  <span :class="row.RF0=='Rise'?'red':'green'">{{row.RFP0}}{{row.RF0=='Rise'?'↑':'↓'}}</span>
                </template>
              </el-table-column> -->
          <el-table-column label="趋势图">
            <template v-slot="{row}">
              <el-button type="text" @click="handlerDetail(row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <PriceLine :visible.sync="editVisible" :edit-data.sync="editData"></PriceLine>
  </div>
</template>

<script>
import { materialAnalysis, materialAreaAnalysis, analysisGetArea } from '@/apis/analysis/priceAnalysis'
import { getMaterialList } from '@/apis/notice/materialNotice'

import Chart from './Chart'
import PriceLine from './PriceLine'
export default {
  components: {
    Chart,
    PriceLine
  },
  name: 'monitor',
  data () {
    return {
      materialNoticeObj: null,
      materials: {
        30: '(砂)',
        34: '(石)',
        5: '(水泥)',
        1: '(商品混凝土)'
      },
      materialsMonth: {
        30: '(砂)',
        34: '(石)',
        5: '(水泥)',
        1: '(商品混凝土)'
      },
      areaList: [],
      areaId: '',
      table1: [],
      lineData: {
        x: [],
        y: []
      },
      table2: [],
      editData: {},
      editVisible: false,
      loading: false
    }
  },
  created () {
    // 获取材料通知信息
    this.getMaterialNotice()

    // 上中两个面板的数据
    this.getMaterialAnalysis()

    // 获取区域数据
    analysisGetArea().then((res) => {
      const areaList = this.areaList = res.data || []
      this.areaId = areaList[0].id || ''

      // 获取对应区域的数据
      this.initPriceLine(areaList[0])
    })
  },
  methods: {
    getMaterialNotice () {
      getMaterialList({
        topiType: 30, // 类型
        pageNum: 1,
        pageSize: 20
      }).then((res) => {
        if (res.code == 200) {
          const notices = res.data.rows || []
          this.materialNoticeObj = notices[0]
        }
      })
    },

    showMessage () {
      this.$alert(`
        <div style="white-space: pre-line;">${this.materialNoticeObj.content}</div>
      `, this.materialNoticeObj.title, {
        confirmButtonText: '确定',
        dangerouslyUseHTMLString: true
      })
    },

    getMaterialAnalysis () {
      // 获取材料类型，请求获取对应的数据
      const materialTypes = Object.keys(this.materials)
      const areaData = []
      const promiseTasks = []
      materialTypes.forEach((materialType) => {
        const promiseIns = materialAnalysis({ materialType }).then((res) => {
          // 省市区数据组成数组
          const {
            // country,
            city,
            province
          } = res.data || {}
          areaData.push(...[
            // country,
            city,
            province
          ].filter(item => {
            if (item) item.name += this.materials[materialType]
            return item
          }))
        })
        promiseTasks.push(promiseIns)
      })

      Promise.all(promiseTasks).then(() => {
        areaData.forEach((item) => {
          const tableItem = {
            name: item.name,
            price: item.price, // 价格指数
            RF0: item.RF0, // 上月涨跌
            RF1: item.RF1, // 去年同期涨跌
            RFV0: item.RFV0 + '%', // 上月涨幅差额
            RFV1: item.RFV1, // 去年同期涨幅差额
            RFP0: item.RFP0, // 上月百分比涨幅百分比
            RFP1: item.RFP1 // 去年同期涨幅百分比
          }
          this.table1.push(tableItem)
          this.lineData.y = item.date
          this.lineData.x.push({
            name: item.name,
            value: item.prices
          })
        })
      })
    },

    handlerAreaChange (areaId) {
      const areaList = this.areaList
      const targetAreaObj = areaList.find((item) => areaId == item.id)
      this.initPriceLine(targetAreaObj)
    },

    handlerDetail (row) {
      this.editData = row
      this.editVisible = true
    },

    initPriceLine (area) {
      if (!area) return
      const props = { PROVINCE: 'regiProvinId', CITY: 'regiCityId', DISTRICT: 'regiCountrId' }
      materialAreaAnalysis({
        [props[area.level]]: area.id,
        level: area.level
      }).then((res) => {
        if (res.code == 200) {
          // 折线图数据
          const lineData = []
          // 有几种产品
          let productList = res.data.map(item => item.materialName + '_' + item.model + '_' + item.unit)
          productList = [...new Set(productList)]
          productList.forEach((item) => {
            res.data.forEach((subItem) => {
            // 标识
              const productLabel = subItem.materialName + '_' + subItem.model + '_' + subItem.unit
              if (item == productLabel) {
                const target = lineData.find((subsubItem) => productLabel == subsubItem.productLabel)
                if (target) {
                  target.date.push(subItem.month + '月')
                  target.prices.push(subItem.price0)
                } else {
                  lineData.push({
                    productLabel,
                    materialName: subItem.materialName,
                    model: subItem.model,
                    unit: subItem.unit,
                    date: [subItem.month + '月'],
                    prices: [subItem.price0]
                  })
                }
              }
            })
          })
          this.table2 = lineData
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-alert__title,
  .el-alert--success.is-light .el-alert__description {
    color: #333;
  }
}
.data-title {
  width: 100%;
  height: 53px;
  background: linear-gradient(90deg, #1532E3 0%, #FFFFFF 100%);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  line-height: 53px;
  padding-left: 17px;
  display: flex;
  align-items: center;
  &.purple {
    background: linear-gradient(90deg, #6B21D6 0%, #FFFFFF 100%)!important;
  }
  &.green {
    background: linear-gradient(90deg, #019F8D 0%, #FFFFFF 100%)!important;
  }
}
.data-content {
  background: #fff;
  padding: 27px 12px;
  font-size: 12px;
  color: #6C7B8A;
}
.data-content2 {
  background: #fff;
  padding: 27px 12px 12px;
  font-size: 12px;
  color: #6C7B8A;
}
.data-item-underline {
  width: 22%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F3F5F8;
}
.data-item {
  width: 47%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data-item-title {
  display: inline-block;
  height: 14px;
  line-height: 14px;
  border-left: 6px solid #5D7DEC;
  padding-left: 6px;
}
.data-item-title2 {
  padding-left: 10px;
}
.data-item-value-box {
  height: 14px;
}
.data-item-value {
  display: inline-block;
  height: 14px;
  line-height: 14px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-right: 6px;
  vertical-align: middle;
}
.data-item-unit {
  display: inline-block;
  height: 14px;
  line-height: 14px;
  vertical-align: middle;
}
</style>
