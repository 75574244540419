<template>
  <div style="width: 100%; height:320px;">
    <div ref="myChart" class="my-chart" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: {
    value: { type: Object },
    unit: { type: String },
    min: {},
    max: {}
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler () {
        this.$nextTick(() => {
          const { y, x } = this.value
          let max = 0
          const series = x.map(item => {
            max = Math.max(max, ...item.value)
            return {
              name: item.name,
              data: item.value,
              type: 'line',
              smooth: true
            }
          })
          const yLabelWidth = (parseInt(max) + '').length * 12
          this.myChart.setOption({
            axisLabel: {
              interval: 0,
              textStyle: {
                color: '#556677'
              },
              // 默认x轴字体大小
              fontSize: 16,
              // margin:文字到x轴的距离
              margin: 15
            },
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              icon: 'circle',
              bottom: 0,
              itemWidth: 8,
              itemGap: 20,
              textStyle: {
                color: '#556677'
              }
            },
            grid: {
              left: yLabelWidth > 50 ? yLabelWidth : 50,
              right: 20,
              top: this.unit ? 50 : 20
            },
            xAxis: {
              type: 'category',
              data: y
            },
            yAxis: {
              name: this.unit ? '单位：' + this.unit : undefined,
              nameGap: this.unit ? 30 : undefined,
              type: 'value',
              min: this.min,
              max: this.max
            },
            series: series
          })
        })
      }
    }
  },
  mounted () {
    const myChart = this.myChart = echarts.init(this.$refs.myChart, null, { renderer: 'svg' })
    this.chartResize(myChart)
  },
  methods: {
    // echart 注册resize事件
    chartResize (myChart) {
      const debounced = XEUtils.debounce(myChart.resize, 500)
      window.addEventListener('resize', debounced) // 监听浏览器窗口变化重新加载
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', debounced)
      })
    }
  }
}
</script>

<style>
.my-chart {
  width: 100%;
  height: 100%;
}
</style>
