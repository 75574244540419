<template>
  <el-dialog
    v-bind="{title:'趋势图',width:'1000px'}"
    v-dialogDrag
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <Chart v-if="visibleDialog" :value="value" :unit="unit"></Chart>
  </el-dialog>
</template>

<script>
import Chart from './Chart'

export default {
  components: {
    Chart
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    }
  },
  computed: {
    unit () {
      return '元/' + (this.editData ? this.editData.unit : '-')
    },
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    },
    value () {
      const editData = this.editData
      if (editData) {
        return {
          y: editData.date,
          x: [
            {
              name: editData.materialName,
              value: editData.prices
            }
          ]
        }
      } else {
        return { x: [], y: [] }
      }
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    handlerClose () {
      this.$emit('update:editData', {})
    }
  }
}
</script>
