import http from '@/utils/http'

// 获取材料信息列表
export function getMaterialList (p) {
  return http.post('/unibu/notice/lastMsgPage', p)
}

// 新建和修改材料信息
export function addMaterial (p) {
  return http.post('/unibu/notice/lastMsgAddMod', p)
}

// 删除材料信息
export function delMaterial (p) {
  return http.post('/unibu/notice/lastMsgDel', p)
}

// 禁用启用材料信息
export function disableMaterial (p) {
  return http.post('/unibu/notice/lastMsgForbid', p)
}
